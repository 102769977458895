import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  displayName=""

  constructor(public router: Router,) { }

  ngOnInit() {
    this.displayName=JSON.parse(localStorage.getItem("user")).displayName;

  }
  
  signout() {

    localStorage.removeItem("AccessToken");
        localStorage.removeItem("user");
      localStorage.clear();
     
      this.router.navigate(['/login']).then(() => { window.location.reload();});
  }
  profile(){
    let user = JSON.parse(localStorage.getItem('user'));
    this.router.navigate([`profile/${user['userIdEncrypt']}`])
  }
}
