import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    routerStateSnapshot: any;
    constructor(private router: Router, private activeRoute: ActivatedRoute) {
        this.routerStateSnapshot = this.router.routerState.snapshot;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
           // debugger;
            let msg: any;
            if (err.status === 401 || err.status === 403) {
                // auto logout if 401 and 403 response returned from api
              //localStorage.clear();
              localStorage.removeItem("AccessToken");
              localStorage.removeItem("isEmployee");
              localStorage.removeItem("user");
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            }
            else if (err.status === 400) {
                msg = err.error.errors.Password[0];
                return throwError(msg);
            }
            else if (err.status === 502) {
                return throwError("Error in backend: 502 Bad Gateway");
            }

          const error = "";//err.error.message || err.statusText;
          return throwError(err);
        
        }))
    }
}
