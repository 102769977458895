<div class="container-fluid p-0" dir="{{textDir}}" >
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo" routerLink='/'>
                            <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main rtl" style="direction:rtl; text-align:right;">
                        <form class="theme-form rtl" [formGroup]="loginForm" (ngSubmit)="login()">
                            <h4 class="text-center">{{'Register_Login' | translate}}</h4>
                           
                            <div class="form-group">
                                <label class="col-form-label">{{'UserName' | translate}}</label>
                                <input class="form-control" type="text" required="" placeholder="{{'UserName' | translate}}" formControlName="username">
                                <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required"
                                     class="text text-danger mt-1">
                                    اسم المستخدم  مطلوب
                                </div>
                                <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.username"
                                     class="text text-danger mt-1">
                                    Invalid Email
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">{{'Password' | translate}}</label>
                                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="{{'Password' | translate}}">
                                <div class="show-hide" style="text-align:left;" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                <div class="show-hide" style="text-align:left;" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                                     class="text text-danger mt-1">
                                    كلمة المرور مطلوبة
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <button class="btn btn-primary btn-block" type="submit" [disabled]="btnDisabled">{{'Register_Login' | translate}}</button>
                            </div>


                          



                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>