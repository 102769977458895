<!-- Page Sidebar Start-->
<div class="logo-wrapper">
    <a routerLink='/'>
        <img class="img-fluid for-light" src="assets/images/logo/MC-logo.svg" alt="">
        <img class="img-fluid for-dark" src="assets/images/logo/MC-logo.svg" alt="">
        <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt=""> -->
    </a>
    <!--<div class="back-btn" (click)="sidebarToggle()">-->
    <!-- <i class="fa fa-angle-left"></i>-->
    <!--<app-feather-icons [icon]="'chevron-left'"></app-feather-icons>
    </div>-->
    <div class="toggle-sidebar" (click)="sidebarToggle()">

        <span class="status_toggle middle sidebar-toggle pin-icon">

            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path d="M505.014,125.61L386.393,6.989c-5.759-5.769-12.797-8.123-19.306-6.465c-6.515,1.66-11.568,7.103-13.866,14.937
	l-5.379,18.37c-2.743,9.363-10.913,23.127-17.841,30.056l-96.355,96.37c-6.947,6.954-20.743,15.402-30.126,18.45l-55.658,18.074
	c-7.719,2.502-13.05,7.712-14.628,14.293c-1.577,6.58,0.815,13.64,6.554,19.361l52.987,52.998l-79.952,79.931
	c-0.188,0.188-0.366,0.385-0.534,0.591L1.81,498.929c-2.604,3.181-2.373,7.817,0.534,10.724c1.554,1.554,3.603,2.343,5.659,2.343
	c1.79,0,3.585-0.598,5.065-1.809L148.057,399.7c0.206-0.168,0.402-0.346,0.59-0.534l79.927-79.927l52.982,52.993
	c4.563,4.572,9.977,7.014,15.322,7.014c1.35,0,2.696-0.156,4.022-0.473c6.59-1.573,11.815-6.9,14.341-14.633l18.042-55.663
	c3.042-9.359,11.491-23.157,18.445-30.124l96.374-96.366c6.909-6.902,20.678-15.059,30.068-17.813l18.371-5.388
	c7.835-2.299,13.282-7.357,14.942-13.879C513.139,138.394,510.779,131.357,505.014,125.61z M137.615,387.57l-72.658,59.469
	l59.459-72.643l79.67-79.649l13.175,13.178L137.615,387.57z M495.976,140.962c-0.146,0.572-1.315,1.703-3.94,2.473l-18.37,5.388
	c-5.725,1.679-12.438,4.775-18.843,8.504l-8.308-8.307c-3.124-3.124-8.189-3.124-11.313,0.001
	c-3.124,3.124-3.124,8.189,0.001,11.313l6.205,6.204c-1.685,1.381-3.25,2.767-4.618,4.133l-96.382,96.374
	c-0.907,0.909-1.827,1.912-2.749,2.966l-6.068-6.067c-3.124-3.124-8.189-3.124-11.313,0.001c-3.124,3.124-3.124,8.189,0.001,11.313
	l7.809,7.808c-4.317,6.886-8.011,14.275-10.025,20.471l-18.038,55.651c-0.818,2.504-2.017,3.824-2.841,4.021
	c-0.813,0.196-2.46-0.435-4.309-2.286l-58.463-58.475c-0.059-0.063-0.111-0.129-0.172-0.19c-0.061-0.061-0.127-0.113-0.189-0.171
	l-82.958-82.976c-1.858-1.853-2.494-3.5-2.3-4.311c0.195-0.812,1.508-1.994,4.006-2.804l55.662-18.075
	c6.201-2.014,13.585-5.703,20.463-10.013l68.884,68.878c1.563,1.562,3.609,2.343,5.657,2.343s4.095-0.781,5.657-2.343
	c3.124-3.125,3.124-8.19,0-11.314l-67.142-67.136c1.061-0.93,2.07-1.857,2.983-2.771l96.352-96.368
	c1.367-1.367,2.754-2.93,4.135-4.612l67.283,67.277c1.563,1.562,3.609,2.343,5.657,2.343s4.095-0.781,5.657-2.343
	c3.124-3.125,3.124-8.19,0-11.314l-69.372-69.366c3.742-6.413,6.847-13.132,8.523-18.856l5.379-18.367
	c0.769-2.62,1.894-3.787,2.463-3.932c0.061-0.016,0.133-0.024,0.216-0.024c0.688,0,2.102,0.571,3.819,2.293
	c0.002,0.002,0.004,0.003,0.006,0.005l118.629,118.63C495.636,138.853,496.12,140.395,495.976,140.962z" />
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
            </span>
       <!-- <app-feather-icons [icon]="'menu'" class="status_toggle middle sidebar-toggle"></app-feather-icons>-->
    </div>
</div>
<div class="logo-icon-wrapper">
    <a href="javascript:void(0)">
        <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="">
    </a>
</div>
<nav class="sidebar-main">
    <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
        <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
    </div>
    <div id="sidebar-menu" [ngStyle]="{ marginLeft : this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px'}">
        <ul class="sidebar-links custom-scrollbar">
            <li class="back-btn">
                <a href="javascript:void(0)">
                    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="">
                </a>
                <div class="mobile-back text-right" (click)="sidebarToggle()">
                    <span>Back</span>
                    <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
            </li>

            <li [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'" *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
                <div *ngIf="menuItem.headTitle1">
                    <h6 class="lan-1">{{menuItem.headTitle1 | translate}}</h6>
                    <p class="lan-2">{{menuItem.headTitle2 | translate}}</p>
                </div>

                <label class="badge badge-{{menuItem.badgeType}}" *ngIf="menuItem.badgeType && menuItem.badgeValue">{{menuItem.badgeValue}}</label>
                <!-- Sub -->
                <a style="margin-bottom: 5px;" href="javascript:void(0)" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{active: menuItem.active}" *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
                    <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title | translate}}</span>
                    <div class="according-menu">
                        <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
                    </div>
                </a>
                <!-- Link -->
                <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{active: menuItem.active}" *ngIf="menuItem.type === 'link'">
                    <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title | translate}}</span>
                    <div class="according-menu">
                        <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
                    </div>
                </a>
                <!-- External Link -->
                <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{active: menuItem.active}" *ngIf="menuItem.type === 'extLink'">
                    <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title | translate}}</span>
                    <div class="according-menu">
                        <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
                    </div>
                </a>
                <!-- External Tab Link -->
                <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{active: menuItem.active}" *ngIf="menuItem.type === 'extTabLink'">
                    <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title }}</span>
                    <div class="according-menu">
                        <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
                    </div>
                </a>
                <!-- 2nd Level Menu -->
                <ul class="sidebar-submenu" [ngClass]="{active: menuItem.active}" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children" [style.display]="menuItem.active ? 'block' : 'none'">
                    <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
                        <!-- Sub -->
                        <a class="submenu-title" href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
                            <span> {{childrenItem.title | translate}}</span>
                            <div class="according-menu">
                                <i class="fa fa-angle-{{childrenItem.active ? 'down' : 'right'}} pull-right" *ngIf="childrenItem.children"></i>
                            </div>
                        </a>
                        <!-- Link -->
                        <a class="submenu-title" [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <span>{{childrenItem.title | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- External Link -->
                        <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <span>{{childrenItem.title | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- External Tab Link -->
                        <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink'">
                            <span>{{childrenItem.title | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- 3rd Level Menu -->
                        <ul class="nav-sub-childmenu submenu-content" *ngIf="childrenItem.children" [ngClass]="{active: childrenItem.active}" [ngClass]="{'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }" [style.display]="childrenItem.active ? 'block' : 'none'">
                            <li *ngFor="let childrenSubItem of childrenItem.children" [ngClass]="{active: childrenSubItem.active}">
                                <!-- Link -->
                                <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]" *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    <span> {{childrenSubItem.title | translate}}</span>
                                </a>
                                <!-- External Link -->
                                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    <span>{{childrenSubItem.title | translate}}</span>
                                </a>
                                <!-- External Tab Link -->
                                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
                                    <span>{{childrenSubItem.title | translate}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>



        </ul>



    </div>
    <!--<div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
        <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
    </div>-->


</nav>

    <!--<div (click)="sidebarToggle()">
        <app-feather-icons [icon]="'menu'" class="status_toggle middle sidebar-toggle"></app-feather-icons>
    </div>-->


