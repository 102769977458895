import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorage } from '../_api/localstorage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router : Router) { 

  }

  canActivate(router, state: RouterStateSnapshot) {
       //  return true;
  
    if (LocalStorage.AccessToken != null && LocalStorage.AccessToken.access_token != null) {

    
     
      return true;
    }
    else{
     // debugger;
      this.router.navigate(['/login'], {queryParams : {returnUrl : state.url}});
      return false;
    }
  }
}
