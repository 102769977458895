import { Component, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppApi } from './shared/_api/app.api';
import { SharedHttpService } from './shared/services/shared-http.service';
declare var webGlObject: any;




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // For Progressbar
  // loaders = this.loader.progress$.pipe(
  //   delay(1000),
  //   withLatestFrom(this.loader.progress$),
  //   map(v => v[1]),
  // );

  textDir: string = 'rtl'

    constructor(private translate: TranslateService, private appapi: AppApi,public shared:  SharedHttpService) {

    //this is to determine the text direction depending on the selected language

    //this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //  if (event.lang == 'ar') {
    //    this.textDir = 'rtl';
    //  }
    //  else {
    //    this.textDir = 'ltr';
    //  }
    //});
        shared.onreload();
  }

 
  ngOnInit() {
    this.appapi.loadlocalization();

    let wegObj = new webGlObject(); 
    if(wegObj.allowcon==false){
      if(window){
        window.console.log=function(){};
     }
     if ( window ) {
     console.log = function(){};
  
     }
    }
   console.log(wegObj.allowcon);
  
  }


}
