import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedHttpService } from 'src/app/shared/services/shared-http.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  
    public notifications: any = ["ff", "ff", "fff", "Fff"];
    public notificationscount: any = "";

  public openNotification: boolean = false;
  contentOfNotification:any='';
  closeResult: string = '';

  constructor(private sharedService: SharedHttpService, private router: Router,private toastr: NotificationService) {}

  ngOnInit() {
    this.getNotifications();

  }
  getNotifications() {
    this.sharedService.get(`api/Notification/GetTop`).subscribe(res => {
        this.notifications = res.data;
        if (res.count > 0)
            this.notificationscount = res.count;
        else
            this.notificationscount = "";

    });
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  showNotificationData(n,e) {
    let notification = e.target.closest('li');
    if(n.isSeen==false){
      notification.classList.add('seen');
        this.markasread(n.notificationId);
       
    }
    this.sharedService.get(`api/Notification/GetByNotificationId?NotificationId=${n.notificationId}`).subscribe(res=>{
      if(res.url != null || res.url != ''){
        let url: string = `case/inbox/details/${res.url}/false`;
        this.router.navigate([url]);
      }else{
        let message = this.sharedService.getTranslatedKey('TheCaseWasNotInTheInbox');
        this.toastr.showError(message, '')
      }
    })
   
  }
  
  markasread(notificationId:any) {
      this.sharedService.get(`api/Notification/MarkAsSeen?NotificationId=${notificationId}`,).subscribe(a => {
          this.getNotifications();
    });
  }
}
