<div class="media profile-media" style="display: inline-flex;">
  <img class="b-r-10" src="assets/images/user.jpg" alt="">
  <div class="media-body"><span>{{displayName}}</span><i class="middle fa fa-angle-down"></i>
    <p class="mb-0 font-roboto">  </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div" style="width: 174px;">
  <!--<li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>{{'Account' | translate}}</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>{{'Inbox' | translate}}</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>{{'Taskboard' | translate}}</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>{{'Settings' | translate}}</span></a></li>-->
  <li (click)="profile();"><a ><app-feather-icons [icon]="'user'"></app-feather-icons><span>{{'Profile' | translate}}</span></a></li>
  <li (click)="signout();"><a ><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>{{'Logout' | translate}}</span></a></li>
</ul>