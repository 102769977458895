import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
// import  { DateFormatterService } from './services/date-formatter.Service';

export class LocalStorage {

 static get isArabic(): boolean {
    return localStorage.getItem("culture") == "en" ? false : true;
  }
  static set isArabic(value: boolean) {
    if (value)
      localStorage.setItem("culture", "ar");
    else
      localStorage.setItem("culture", "ar");
  }
 

 
  static get user(): string {
    return localStorage.getItem("user") ;
  }

  static set user(value: string) {
    localStorage.setItem("user", value);
  }

  static get AccessToken(): any {
    if (localStorage.getItem("AccessToken") == null)
      return   {access_token:null, refresh_token:null};
    return JSON.parse(localStorage.getItem("AccessToken"));
  }

  static set AccessToken(value: any) {
    localStorage.setItem("AccessToken", JSON.stringify(value));
  }

  
  static get localization_ar(): any {
    return JSON.parse(localStorage.getItem("localization_ar"));
  }

  static set localization_ar(value: any) {
    localStorage.setItem("localization_ar", JSON.stringify(value));
  }



  static get localization_en(): any {
    return JSON.parse(localStorage.getItem("localization_en"));
  }

  static set localization_en(value: any) {
    localStorage.setItem("localization_en", JSON.stringify(value));
  }
   
  static get localization_lastUpdateDate(): string {
    return localStorage.getItem("localization_lastUpdateDate");
  }
  static set localization_lastUpdateDate(value: string) {

    localStorage.setItem("localization_lastUpdateDate", value);

  }


    //-----------------------------------------------------------------------------

    
  
    static get calendar(): string {
      return localStorage.getItem("calendar");
    }
    static set calendar(value: string) {
  
      localStorage.setItem("calendar", value);
  
    }
  
    //-----------------------------------------------------------------------------

    // static GetGeoDate(date: NgbDateStruct, dateFormatterService: DateFormatterService): string {
    //   if (LocalStorage.calendar == "ummalqura") {
    //     let h = dateFormatterService.ToGregorian(date);
    //     return h.year + "-" + h.month + "-" + h.day;
    //   }
    //   else {
    //     return date.year + "-" + date.month + "-" + date.day;
    //   }
    // }

}
