import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toDateOnly'
})
export class ToDateOnlyPipe implements PipeTransform {

  transform(value: string ): string {
      if (value != null && value.trim() != "") {
          let ret = "";
          ret =  moment(value).format('DD-MM-YYYY') ;

          return ret;
      }
      return "";
  }

}
