import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedHttpService } from 'src/app/shared/services/shared-http.service';
import { AppApi } from 'src/app/shared/_api/app.api';
import { LocalStorage } from 'src/app/shared/_api/localstorage';
import { NavService, Menu } from '../../../../services/nav.service';
declare var $: any;

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {


  public language: boolean = false;
  isArabic: boolean;

  public languages: any[] = [{
    language: 'العربية',
    code: 'ar',
    type: 'SA',
    icon: 'sa'
  },
  {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }
  ]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  constructor(private sharedService: SharedHttpService, private translate: TranslateService, private appapi: AppApi
    , public navServices: NavService, private router: Router) { }

  ngOnInit() {
    this.isArabic = LocalStorage.isArabic;

  }



  changeLanguage() {

    let body = document.querySelector("body");
    let arabic = true;
    let settings = JSON.parse(localStorage.getItem('themesettings'));

    // ! Set direction 

    if (settings.dir == 'ltr') {
      settings.dir = 'rtl';
      arabic = true;

      body.classList.remove("ltr");
      body.classList.add("rtl");


      this.sharedService.get('api/Localization/json/ar').subscribe(result => {
        LocalStorage.localization_ar = result;
        this.translate.setTranslation('ar', result);
        LocalStorage.isArabic = true;
        this.translate.use('ar');
      });

    } else {


      body.classList.add("ltr");
      body.classList.remove("rtl");



      settings.dir = 'ltr';
      arabic = false;


      this.sharedService.get('api/Localization/json/en').subscribe(result2 => {
        LocalStorage.localization_en = result2;
        this.translate.setTranslation('en', result2);
        LocalStorage.isArabic = false;
        this.translate.use('en');
      });

    }

    localStorage.setItem("themesettings", JSON.stringify(settings))

  }




}

