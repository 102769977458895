import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorage } from '../_api/localstorage';
//import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(/*private storage: StorageMap*/){
        
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        
        ////Get Token data from local storage
      let token = LocalStorage.AccessToken.access_token  ;


    


         // LocalStorage.AccessToken.refresh_token

      //console.log(request.url);
      let x: string = request.url;



      if (token != null
        // && x.indexOf("api/Localization/json")==-1
      ) {
            request = request.clone({
              setHeaders: {
             

                   /// 'Accept': 'application/json',
                //'Access-Control-Max-Age': '600',
               // 'Cache-Control': 'no-cache',
                //'Access-Control-Allow-Origin': '*',
                     'Authorization': 'Bearer ' + token,
                    // 'Content-Type': 'application/json' ,   //deleted to use upload file multi-part

                 //   'Content-Type': 'application/json'


                   
                }
            });
           //  request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        }
      

        return next.handle(request);
    }
}
