
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SharedHttpService } from '../services/shared-http.service';

export interface LoginModel {
  username: string;
  password: string;
}


export interface AccessToken {
  access_token: string;
  refresh_token: string;
}

export interface AuthTickect {
  defaultCalendar: string,
  defaultCulture: string,
  defaultInboxFilter: string,
  delegationDefaultID: number,
  email: string,
  fullName: string,
  fullNameAr: string,
  fullNameEn: string,
  isAdmin: boolean,
  isEmployee: boolean,
  isHijriDate: boolean,
  isShowCalender: boolean,
  isShowPeriodStatistics: boolean,
  isShowTask: boolean,
  isShowTransactionOwner: boolean,
  isShowTransactionRelated: boolean,
  isVip: boolean,
  organizationId: number,
  organizationNameAr: string,
  organizationNameEn: string,
  permissions: string[],
  profileImageFileId: string,
  roleId: number,
  roleNameAr: string,
  roleNameEn: string,
  rolesCount: number,
  themeValue: any,
  userId: number,
  userIdEncrypt: string,
  userImage: string,
  userName: string,
  userRoleId: number,
  userRoles: any
}




@Injectable({
  providedIn: 'root'
})
export class LoginApi {


  constructor(private shared: SharedHttpService, private http: HttpClient) { }

  Login(model: LoginModel): Observable<AccessToken> {
    let culture = localStorage.getItem("culture");
    return this.shared.post('api/Account/Login', model);
  }


  GetUserAuthTicket(): Observable<AuthTickect> {
    return this.shared.get("api/Account/GetUserAuthTicket");
  }


}
