import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorage } from '../_api/localstorage';
import { NotificationService } from './notification.service';
declare var webGlObject: any;


@Injectable({
    providedIn: 'root'
})
export class SharedHttpService {
  baseUrl: string = "ht0/";

    CurrentUserRoleId: any = 0;
    CurrentUserId: any = 0;
    CurrentFullRoleName: any = "";
    CurrentRoleId: any = 0;
    CurrentCommitteeId = 0;
    CurrentOrganizationId = 0;
    correspondanceDetailsObj: any;
    openModal = new BehaviorSubject<any>('manoj');

    constructor(private toastr: NotificationService, private http: HttpClient, private translate: TranslateService) {


        let wegObj = new webGlObject();
        this.baseUrl= wegObj.baseUrl;


        this.onreload();



    }

    //get all Data
    downloadFileGet(url: any): Observable<any> {
        return this.http.get(this.baseUrl + url, { responseType: 'arraybuffer' });
    
      }
    get(url: any): Observable<any> {
        return this.http.get<any>(this.baseUrl + url);
    }
    post(url: any, data: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + url, data);
    }
    put(url: any, data: any): Observable<any> {
        return this.http.put<any>(this.baseUrl + url, data);
    }



    getString(url: any): Observable<any> {
        return this.http.get(this.baseUrl + url, { responseType: 'text' });

    }


    delete(url, data): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: data,
        };
        return this.http.delete<any>(this.baseUrl + url, options);
    }


    getdeputies(): any {

        return JSON.parse(localStorage.getItem("user")).deputies;
    }


    getTranslatedKey(key: string) {
        let res: string = "aaa";



        if (LocalStorage.isArabic) {
            var ar = LocalStorage.localization_ar;
            return ar[key];
        }
        else {
            var en = LocalStorage.localization_en;
            return en[key];
        }

        //this.translate.get(key).subscribe(results => {

        //  res = results;
        //});
        //return res;




        



    }

    onreload() {



        //if (localStorage.getItem("user") != null) {
        //    try {
        //        // this.roleId = JSON.parse(localStorage.getItem("user")).roleId;
        //    } catch (e) {

        //    }


        //    // var user=
        //}

        try {
    let user = JSON.parse(localStorage.getItem("user"));
        this.CurrentUserId = user.userId;

        if (this.CurrentUserRoleId == null || this.CurrentUserRoleId ==0   ) {


            let rolesArr = user.userRoles;
            rolesArr.forEach(r => {
                if (r.lastSelected == true) {
                    this.CurrentUserRoleId = r.userRoleId;
                    this.CurrentRoleId = r.roleId;
                    if (r.organizationId != null) {
                        this.CurrentOrganizationId = r.organizationId;
                        this.CurrentCommitteeId = null;
                    }


                    if (r.committeeId != null) {
                        this.CurrentOrganizationId = null;
                        this.CurrentCommitteeId = r.committeeId;
                    }


                    if (r.committeeId != null && r.organizationId != null) {
                        this.CurrentCommitteeId = null;
                        this.CurrentOrganizationId = null;



                    }


                }
            });

        }
        }
        catch   {

        }
    
 
    }


      parseArabic2(str: string) {
        let x = str
            .split("٠").join("0")
            .split("١").join("1")
            .split("٢").join("2")
            .split("٣").join("3")
            .split("٤").join("4")
            .split("٥").join("5")
            .split("٦").join("6")
            .split("٧").join("7")
            .split("٨").join("8")
            .split("٩").join("9");
        return x;
    }



    TranslateKey(key: any): Observable<any> {
        return this.translate.get(key);
    }



    showErrorKey(Key: any) {
        this.translate.get(Key).subscribe(x => {
            this.toastr.showError(x, "خطأ");
        });
    }

    showSuccessKey(Key: any) {
        this.translate.get(Key).subscribe(x => {
            this.toastr.showSuccess(x, "");
        });
    }






}
