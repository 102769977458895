import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedHttpService } from 'src/app/shared/services/shared-http.service';

@Component({
  selector: 'app-case-details-modal',
  templateUrl: './case-details-modal.component.html',
  styleUrls: ['./case-details-modal.component.scss']
})
export class CaseDetailsModalComponent implements OnInit {
  closeResult: string = '';
  caseInfoAfterVerficationCaseSource :any;
  @ViewChild('verficationModal') modal;



  constructor(
    private modalService: NgbModal,
    private sharedService:SharedHttpService
  ) { 
    
  }

  ngOnInit(): void {
    this.sharedService.openModal.subscribe(data=>{
      this.open(this.modal,data);
    })
  }
  open(content: any,inbox) {
    this.caseInfoAfterVerficationCaseSource=[];
    this.sharedService.get(`api/Case/GetCorrespondenceInfo?correspondenceTypeId=${inbox.correspondenceTypeId}&year=${inbox.correspondenceYear}&correspondenceNumber=${inbox.correspondenceNumber}`).subscribe(res=>{
        this.caseInfoAfterVerficationCaseSource=res;
         
    })

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true,size:'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
 
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
