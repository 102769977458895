<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span *ngIf="notificationscount!='' && notificationscount<=50" class="badge badge-pill badge-danger">{{ notificationscount}}</span>
  <span *ngIf="notificationscount>50" class="badge badge-pill badge-danger">50+</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">{{'Notifications'|translate}}</h6>
  </li>
   <ng-container *ngIf="notifications!=null">
    <li *ngFor="let n of notifications.slice(0, 4)" [ngClass]="{'unseen': !n.isSeen}">
      <!-- <p>
        <i (click)="shownotificationdata(n,$event,popUpModal);" class="fa fa-circle-o mr-3 font-primary"></i>{{n.notificationTtile}}<span class="pull-right">{{n.createdOn  | toDateOnly}}</span>
      </p> -->
      <a (click)="showNotificationData(n,$event);" class="dropdown-item islink">

        <div class="row">
          <a class="col-md-12 py-2">
            <b style="font-size:12px;">{{n.notificationTtile}}</b>
          </a>
        </div>
      </a>
    </li>
  </ng-container>
  
  <li>
    <a class="btn btn-primary" routerLink="/notifications"> {{'More' | translate}}...</a>
  </li>
    
  
</ul>

