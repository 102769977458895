import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../services/layout.service";
import { SharedHttpService } from "../services/shared-http.service";

import { LocalStorage } from "./localstorage";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AppApi {


  private Viewsettings = {
    dark: 0,
    dir: 'rtl',
    view: 'default'
  };



  constructor(private shared: SharedHttpService, private translate: TranslateService,
     public layout: LayoutService) { }


  loadlocalization() {

    let datestr = LocalStorage.localization_lastUpdateDate;


    this.shared.get('api/Localization/GetLastUpDateTime').subscribe(updatedate => {
      if (datestr == null || datestr == "" || updatedate != datestr || LocalStorage.localization_en == null || LocalStorage.localization_ar == null) {

        LocalStorage.localization_lastUpdateDate = updatedate;
        this.shared.get('api/Localization/json/ar').subscribe(result2 => {
          LocalStorage.localization_en = result2;
          this.translate.setTranslation('en', result2);
          LocalStorage.isArabic = false;
          this.translate.use('ar');
        });
        this.shared.get('api/Localization/json/ar').subscribe(result => {
          LocalStorage.localization_ar = result;
          this.translate.setTranslation('ar', result);
          LocalStorage.isArabic = true;
          this.translate.use('ar');
        });

      }
      else {
        //this.translate.setTranslation('ar', LocalStorage.localization_en);
        this.translate.setTranslation('ar', LocalStorage.localization_ar);
        var current = "ar";
        this.translate.use(current);
      }

    });


  }



  setclauture(cal: string) {
    //let url = `api/Culture/UpdateCultureSession?key=${cal}`;
    //this.shared.post(url, "{ key: culture }").subscribe(result => {

    //});
  }



}
