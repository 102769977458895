import { Component, OnInit } from '@angular/core';
import { SharedHttpService } from '../../services/shared-http.service';

@Component({
  selector: 'app-correspondance-details',
  templateUrl: './correspondance-details.component.html',
  styleUrls: ['./correspondance-details.component.scss']
})
export class CorrespondanceDetailsComponent implements OnInit {
    caseInfoAfterVerficationCaseSource: any;
    constructor(private shared: SharedHttpService) {
      
    }

    ngOnInit(): void {
        this.caseInfoAfterVerficationCaseSource = this.shared.correspondanceDetailsObj;

    }

    download(attachment) {
        this.shared.downloadFileGet(`api/Case/DownloadCros?DocId=${attachment.id}&filename=${attachment.name}`).subscribe(data => {
            this.downloadFile(data, attachment);
        });
    }

    downloadFile(data: any, attachment: any) {
        var blob = new Blob([data], { type: attachment.dataType });
        var url = window.URL.createObjectURL(blob);

        var fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.download = attachment.fileneme;
        fileLink.click();
    }

}
