import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'toDateTime'
})
export class ToDateTimePipe implements PipeTransform {


    transform(value: string): string {
        try {
if (value != null && value.trim() != "") {
    let ret = "";
    console.log(value);
      ret = moment(value).format('YYYY-MM-DD h:mm a');

      return ret;
    }
   
        } catch (e) {
 return "";
        }
    


  }

}
