import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toTimeOnly'
})
export class ToTimeOnlyPipe implements PipeTransform {



    transform(value: string): string {
        if (value != null && value.trim() != "") {
            let ret = "";
            ret = moment(value).format('h:mm a');

            return ret;
        }
        return "";


    }

}
