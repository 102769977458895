import { Injectable } from '@angular/core';

import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message, title) {
    // this.toastr.success(message, title, config)
    const config: Partial<IndividualConfig> = {
        timeOut: 3000,
        toastClass: "toast-success",
        



    };
    this.toastr.success(message, title, config);
  }

  showError(message, title) {
    const config: Partial<IndividualConfig> = {
      timeOut: 3000,
      toastClass: "toast-error",
    };
    this.toastr.error(message, title, config);
  }

  showInfo(message, title) {
    const config: Partial<IndividualConfig> = {
      timeOut: 3000,
      toastClass: "toast-info",
    };
    this.toastr.info(message, title, config)
  }

  showWarning(message, title) {
    const config: Partial<IndividualConfig> = {
      timeOut: 3000,

      toastClass: "toast-warning",
    };
    this.toastr.warning(message, title), config
  }
}
