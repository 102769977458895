import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SharedHttpService } from './shared-http.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router, shared :SharedHttpService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
 this.MENUITEMS.push({ title: 'Home', icon: 'home', path: '/', type: 'link' });
		if (shared.CurrentRoleId == 2 || shared.CurrentRoleId == 3 || shared.CurrentRoleId == 5) {
		/*	this.MENUITEMS.push({ title: 'CreateCase', icon: 'file-plus', path: '/case/add-case', type: 'link' });*/
		     this.MENUITEMS.push({ title: 'Draft', icon: 'file-text', path: '/boxes', type: 'link' });
        }

		if (shared.CurrentRoleId == 2 || shared.CurrentRoleId == 3 || shared.CurrentRoleId == 4 || shared.CurrentRoleId == 5 || shared.CurrentRoleId == 6 || shared.CurrentRoleId == 7 || shared.CurrentRoleId == 9) {
	       
			this.MENUITEMS.push({ title: 'المستلمة', icon: 'mail', path: '/boxes/inbox', type: 'link' });
			
		/*	this.MENUITEMS.push({ title: 'MyInbox', icon: 'user-check', path: '/boxes/my-inbox', type: 'link' });*/
			this.MENUITEMS.push({ title: 'المرسلة', icon: 'share', path: '/boxes/outbox', type: 'link' });
			this.MENUITEMS.push({ title: 'Finished', icon: 'check-circle', path: '/boxes/finished', type: 'link' });
			this.MENUITEMS.push({ title: 'Saved', icon: 'save', path: '/boxes/saved', type: 'link' });
			if (shared.CurrentRoleId == 2 || shared.CurrentRoleId == 3 )
		     	this.MENUITEMS.push({ title: 'Deleted', icon: 'trash', path: '/boxes/deleted', type: 'link' });

			this.MENUITEMS.push();
			this.MENUITEMS.push();
        }

		if (shared.CurrentRoleId == 1) {

			this.MENUITEMS.push({ title: 'نبأ', icon: 'navigation', path: '/ns/nabaa', type: 'link' });
			this.MENUITEMS.push({ title: 'سداد', icon: 'dollar-sign', path: '/ns/sadad', type: 'link' });


			this.MENUITEMS.push({
				title: 'Reports', icon: 'bar-chart-2', type: 'sub', badgeType: 'success', active: true, children: [

	

					{ path: '/reports', title: 'CommitteesReport', type: 'link' },
					{ path: '/reports/OrganizationsReport', title: 'organizationsReport', type: 'link' },
					{ path: '/reports/CommitteeStatusReport', title: 'CommitteeStatusReport', type: 'link' },
					{ path: '/reports/OrganizationsStatusReport', title: 'organizationsStatusReport', type: 'link' },
					{ path: '/reports/PerformanceReport', title: 'PerformanceReport', type: 'link' },
					 
				]
			});


			this.MENUITEMS.push({
			title: 'settings', icon: 'settings', path: '/settings', type: 'sub', badgeType: 'success', active: true, children: [
				{ path: '/settings/committees', title: 'Committees', type: 'link' },
				
				{ path: '/settings/facts', title: 'Facts', type: 'link' },
				{ path: '/settings/legal-articles', title: 'LegalArticles', type: 'link' },
				{ path: '/settings/case-source', title: 'casesource', type: 'link' },
				{ path: '/settings/localization', title: 'Localizations', type: 'link' },
				{ path: '/settings/reasons', title: 'Reasons', type: 'link' },
				{ path: '/settings/users', title: 'Users', type: 'link' },
				{ path: '/settings/attachment-types', title: 'أنواع المرفقات', type: 'link' },
				{ path: '/settings/organization', title: 'organization', type: 'link' },
				// { path: '/settings/priority', title: 'priority', type: 'link' },
				{ path: '/settings/violations', title: 'Violations', type: 'link' },
				{ path: '/settings/penalties', title: 'Penalties', type: 'link' },
				{ path: '/settings/collect-way', title: 'collectway', type: 'link' },

 
			]
			});



		}
		if (shared.CurrentRoleId == 4 || shared.CurrentRoleId == 5 || shared.CurrentRoleId == 6 || shared.CurrentRoleId == 7) {
			this.MENUITEMS.push({
				title: 'Reports', icon: 'bar-chart-2', type: 'sub', badgeType: 'success', active: true, children: [ 

					{ path: '/reports', title: 'CommitteesReport', type: 'link' }, 
					{ path: '/reports/CommitteeStatusReport', title: 'CommitteeStatusReport', type: 'link' }, 
					{ path: '/reports/PerformanceReport', title: 'PerformanceReport', type: 'link' },

				]
			});
        }
		if (shared.CurrentRoleId == 7 || shared.CurrentRoleId == 4) {
			this.MENUITEMS.push({
			title: 'settings', icon: 'settings', path: '/settings', type: 'sub', badgeType: 'success', active: true, children: [
				 
				{ path: '/settings/penalties', title: 'Penalties', type: 'link' }


			]
		});
		}


		if (shared.CurrentRoleId == 2 || shared.CurrentRoleId == 3) {
			this.MENUITEMS.push({
				title: 'Reports', icon: 'bar-chart-2', type: 'sub', badgeType: 'success', active: true, children: [


					 
					{ path: '/reports/OrganizationsReport', title: 'organizationsReport', type: 'link' }, 
					{ path: '/reports/OrgDetailsReport', title: 'تقرير المخالفات', type: 'link' }, 
					{ path: '/reports/OrganizationsStatusReport', title: 'CommitteeStatusReport', type: 'link' },
					{ path: '/reports/PerformanceReport', title: 'PerformanceReport', type: 'link' },

				]
			});
		}

		// if (shared.CurrentRoleId == 3) {
		// 	this.MENUITEMS.push({
		// 	title: 'settings', icon: 'settings', path: '/settings', type: 'sub', badgeType: 'success', active: true, children: [
				
				
		// 		{ path: '/settings/violations', title: 'Violations', type: 'link' },




		// 	]
		// });
        // }





	}

	ngOnDestroy() {
		// this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}



	//let settings=


	MENUITEMS: Menu[] = [

	 
		
	];

	MEGAMENUITEMS: Menu[] = [
		 
	];

	LEVELMENUITEMS: Menu[] = [
		
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
