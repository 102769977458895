

<ng-template #verficationModal let-modal>
    <div class="TransApp">
        <div class="modal-header"><h2 class="fs-5 mb-0 text-secondary">تفاصيل المعاملة</h2></div>
        <div class="modal-body text-center">
            <div class="form-group mt-3 row" >
                <div style="text-align: right;" class="col-12">
                    <b>العنوان :</b> {{caseInfoAfterVerficationCaseSource.correspondenceSubject}}
                </div>
            </div>
            <div class="form-group mt-3 row">
                <div style="text-align: right;" class="col-4">
                    <b>رقم المعاملة :</b> {{caseInfoAfterVerficationCaseSource.correspondenceNumber}}
                </div>
                <div style="text-align: right;" class="col-4">
                    <b>السنة الهجرية :</b> {{caseInfoAfterVerficationCaseSource.hijricYear}}
                </div>
                <div style="text-align: right;" class="col-4">
                    <b>درجة السرية :</b> {{caseInfoAfterVerficationCaseSource.confidentialityDesc}}
                </div>
            </div>
            <div class="form-group mt-3 row">
                <div  style="text-align: right;" class="col-6">
                    <b>من إدارة :</b> {{caseInfoAfterVerficationCaseSource.ownerDepartmentDesc}}
                </div>
                <div style="text-align: right;" class="col-6">
                    <b>إلي إدارة :</b> {{caseInfoAfterVerficationCaseSource.receiveByDepartmentDesc}}
                </div>
            </div>
            <div class="form-group mt-3 row">
                <div style="text-align: right;" class="col-12">
                    <b>المرفقات : </b>
                    <a *ngFor="let doc of caseInfoAfterVerficationCaseSource.documentList" (click)="download(doc);" class="btn btn-sm btn-outline-primary me-1">
                        {{doc.name}}
                        &nbsp;<i class="fa fa-download"></i>
                    </a>
                </div>
                
            </div>
            <div class="form-group mt-3 row">
               
                <div style="text-align: right;" class="col-12">
                    <div class="row"> 
                        <b class="col-2 me-3">ملاحظات :</b> 
                        <p class="col-9">{{caseInfoAfterVerficationCaseSource.remarks}} </p> 
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          
            <button type="button" class="btn btn-danger" (click)="modal.dismiss()">
                إغلاق
            </button>
            
        </div>
    </div>
   
  </ng-template>