
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginApi, LoginModel } from '../../shared/_api/login.api';
import { LocalStorage } from "../../shared/_api/localstorage";
import { NotificationService } from "../../shared/services/notification.service";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SharedHttpService } from 'src/app/shared/services/shared-http.service';
import { AppApi } from '../../shared/_api/app.api';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public errorMessage: any;
  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  btnDisabled = false;
  submitted = false;


  textDir: string = 'ltr'
  
  constructor(private formBuilder: FormBuilder,
    private router: Router, private modalService: NgbModal,
      private api: LoginApi,
      private apiapi: AppApi,

    private toastr: NotificationService, private translate: TranslateService,
    private route: ActivatedRoute,
    private sharedService: SharedHttpService,
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    });
  }

    ngOnInit() {
        this.apiapi.loadlocalization();
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    //  this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    //   this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    //  this.authService.signInFacebok();
  }



  login() {
  
    this.btnDisabled = true;
    if (this.loginForm.valid) {

      this.api.Login(this.loginForm.value as LoginModel).subscribe(result => {
        if (result.access_token != null) {
          LocalStorage.AccessToken = { access_token: result.access_token, refresh_token: result.refresh_token };
         // this.updatesession();
          this.getuserdata();
        }
        else {
          this.translate.get("InvalidCredentials").subscribe((text: string) => {
            this.toastr.showError(text, "");
          });
        }
      }, error => {
        this.toastr.showError("خطأ في اسم المستخدم أو كلمة المرور", '');
      }
      );
    }
    else
      this.submitted = true;
    this.btnDisabled = false;

  }


  getuserdata() {
    this.api.GetUserAuthTicket().subscribe(result => {
      LocalStorage.user = JSON.stringify(result);
        this.sharedService.onreload();
      let returnUrl: any = this.route.snapshot.queryParams['returnUrl'] || '/';
        console.log(returnUrl, "return url");

      if (returnUrl != null && returnUrl != "") {
        if (returnUrl.indexOf("login") != -1) {
          this.router.navigate(["'/dashborad'"]);
        } else
          this.router.navigateByUrl(returnUrl);
      }
      else
        this.router.navigate(["'/dashborad'"]);
    });
  }


  updatesession() {
    var culture = LocalStorage.isArabic ? "ar" : "en";
    //let url = (culture == null) ?
    //  'api/Culture/UpdateCultureSession?key=ar' : 'api/Culture/UpdateCultureSession?key=' + culture;
    //this.sharedService.post(url, "{ key: culture }").subscribe(result => {

    //});
  }

}
