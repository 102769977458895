import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { SharedHttpService } from '../../services/shared-http.service';
import { LocalStorage } from "../../_api/localstorage";
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { AppApi } from '../../_api/app.api';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;
  public rolesArr: any = [];
  public userRoleId: any;
  loading: boolean = false;


  private Viewsettings = {
    dark: 0,
    dir: 'rtl',
    view: 'default'
  };

  constructor(public layout: LayoutService,
    public navServices: NavService, public sharedService: SharedHttpService,
    public router: Router, private toastr: NotificationService,
    private appapi: AppApi,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  ngOnInit() {
    this.elem = document.documentElement;
   
      this.rolesArr = JSON.parse(localStorage.getItem("user")).userRoles;
       
    this.rolesArr.forEach(r => {
      if (r.lastSelected == true) {
          this.userRoleId = r.userRoleId;
          this.sharedService.CurrentUserRoleId = r.userRoleId;
          this.sharedService.CurrentRoleId = r.roleId;
          console.log("LstSelected", r);
          if (r.organizationId != null) {
              this.sharedService.CurrentOrganizationId = r.organizationId;
              this.sharedService.CurrentCommitteeId =null;
          }


          if (r.committeeId != null) {
              this.sharedService.CurrentOrganizationId =null;
              this.sharedService.CurrentCommitteeId = r.committeeId;
          }


          if (r.committeeId != null && r.organizationId != null) {
              this.sharedService.CurrentCommitteeId = null;
              this.sharedService.CurrentOrganizationId = null;



          }


      }
    });


  }

  selectUserRole(e) {
    this.loading = true;

      console.log(e);
    this.sharedService.get("api/Account/GetUserAuthTicket?userRoleId=" + e.userRoleId).subscribe(result => {

        console.log(result);
      LocalStorage.user = JSON.stringify(result);

      this.sharedService.post("api/Account/RefreshToken", { refreshToken: LocalStorage.AccessToken.refresh_token }).subscribe(result2 => {


        if (result2.access_token != null) {


          LocalStorage.AccessToken = { access_token: result2.access_token, refresh_token: result2.refresh_token };

          if (this.router.url != '/dashboard')
            this.router.navigate(['/dashboard']).then(() => { window.location.reload() });
          else
            window.location.reload();
        }
        this.loading = false;
      });

    },
      error => {

        this.toastr.showError(error, '');

      });
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
      this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
      if (this.dark == true) {
          this.document.body.classList.add('dark');
      }
      else {
          this.document.body.classList.remove('dark');
      }
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }



}
